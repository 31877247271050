@use './styles/_global.scss' as g;

body {
	margin: 0;
	background-color: #f7f7f7;
	font-family: 'Inter', sans-serif;
	font-size: g.$description;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: g.$textDark;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.rotate-infinite {
	margin: auto;
}

.App {
	width: 100%;
}

.App-header {
	position: absolute;
	width: 100%;
}

// Full page overlay behind the setup card
.fullpage-dark-overlay {
	background: rgba(0, 0, 0, 0.5);
	position: fixed;
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	z-index: 10;
}

.action-on-fullpage-overlay {
	position: relative;
	z-index: 11;
}

// Grainy texture on the green background
.grain-texture {
	background: linear-gradient(-60deg, #327a65, #062015);
	background-size: 150% 200%;
	animation: gradient 10s ease infinite;
	animation-direction: alternate;
	filter: url(#noiseFilter);
	height: 50vh;
	position: fixed;
	width: 100%;
	z-index: -2;
}

// Loader icon styling
.astronautLoaderContainer {
	display: flex;
	justify-content: center;
	flex-direction: column;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 50%;
	align-items: center;
	z-index: -1;

	.astronautLoaderImage {
		-webkit-animation: floatingLoader 1s infinite alternate;
		animation: floatingLoader 1s infinite alternate;
		max-width: 70px;
	}

	p {
		max-width: 150px;
		text-align: center;
	}

	svg {
		ellipse {
			animation: changeSize 1s infinite alternate;
			fill: g.$gray3;
			opacity: 20%;
		}
	}
}

// Background animation
@keyframes gradient {
	0% {
		background-position: 0%;
	}
	50% {
		background-position: 100% 100%;
	}
	100% {
		background-position: 0%;
	}
}

// Astronaut loader animation
@keyframes floatingLoader {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(-10px);
	}
}

// Astronaut loader shadow animation
@keyframes changeSize {
	0% {
		rx: 30px;
		ry: 10px;
	}
	100% {
		rx: 15px;
		ry: 5px;
	}
}

// Circular progress style
.MuiCircularProgress-root {
	display: flex;
}

.loginContainer {
	display: flex;
	margin: 40px auto;
	flex-direction: column;
	gap: 20px;
	.loginLogoContainer {
		width: 100%;
		img {
			max-width: 160px;
			margin: 20px auto;
			display: flex;
		}
	}
}

.form-screen-container {
	margin-top: 100px;
}

// Scrollbars styling
/* width */
::-webkit-scrollbar {
	width: 14px;
}

/* Track color */
::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 0px;
	border-width: 0;
	// box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

@mixin padding-for-scrollbar {
	background-clip: content-box;
	border: 3px solid transparent;
	border-radius: 10px;
}

/* Bar color */
::-webkit-scrollbar-thumb {
	background: #bababa;
	@include padding-for-scrollbar;
}

/* On drag */
::-webkit-scrollbar-thumb:hover {
	background: #aeaeae;
	@include padding-for-scrollbar;
}
