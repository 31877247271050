@import url('https://fonts.googleapis.com/css?family=Poppins:200,400,700');
@import url('https://fonts.googleapis.com/css?family=Inter:200,300,400,500,600,700');

// Colors
$primary: #004225;
$secondary: #00422599;
$primaryDark: #0b3d39;
$primary-low-opacity: #00422514;
$accent1: #c49636;
$accent1-low-opacity: #c496360d;
$accent2: #031b68;
$accent2-low-opacity: #031b680d;
$accent3: #8e328f;

$white: #fff;
$white2: #e7e7e7;

$neutral1: #ffffff;
$neutral2: #fafafa;
$neutral3: #dddddd;
$neutral4: #cacaca;
$neutral5: #a6a6a6;
$neutral6: #909090;
$neutral6_5: #717171;
$neutral7: #212121;

$black: #000;
$black2: #212121;

$gray: #e0e0e0;
$gray2: #fbfbfb;
$gray3: #333333;

// $error: red;
$error: #f05656;
$warning: #fcd853;
$success: #89cdbb;
$errorDark: #e43b3b;
$warningDark: #f7bd43;
$successDark: #004225;
$errorMedium: #ff8769;
$warningMedium: #ffcd73;
$successMedium: #89d7be;
$errorLighter: #e43b3b1a;
$warningLighter: #f7bd431a;
$successLighter: #0042251a;
$errorLight: #e43b3b0d;
$warningLight: #f7bd430d;
$successLight: #0042250d;
$accordionBg: #eaf5f1;

$cooling: #75b5b9;
$coolingLight: #bddfe1;

// Breakpoints
$laptop: 1500px;

//Font colors
$textDark: #212121;
$textLight: #6e6e6e;
$textPlaceholder: #909090;
$coralRed: #e87053;
$coralRedLight: #f4ab9a;
$aquaBlue: #75b5b9;
$aquaBlueLight: #aacfd0;

//Font sizes
$pageTitle: 1.6rem;
$cardTitle: 0.9rem;
$keyText: 0.8rem;
$description: 0.85rem;
$inputLabels: 0.85rem;
$instructions: 0.85rem;
$label: 0.85rem;
$staticText: 0.8rem;

//Font weights:
$normalWeight: 400;
$boldWeight: 800;
$lightWeight: 200;

//Line heights:
$keyTextLineHeight: 1.2rem;
$staticTextLineheight: 0.9rem;

//Font family:
$primaryFontFamily: 'Inter', sans-serif;
$secondaryFontFamily: 'Poppins', serif;

// EE colors
$moderate: rgba(255, 255, 0, 1);
$critical: rgba(200, 0, 0, 1);

$moderateBackground: rgba(255, 255, 244, 1);
$criticalBackground: rgba(255, 231, 226, 1);

// Icon size
$iconSize: 60px;
// Table row height
$rowHeight: 60px;
:export {
	pageTitle: $pageTitle;
	cardTitle: $cardTitle;
	description: $description;
	instructions: $instructions;

	primaryFontFamily: $primaryFontFamily;
	secondaryFontFamily: $secondaryFontFamily;

	//Text colors
	primary: $primary;
	primaryDark: $primaryDark;
	primary-low-opacity: $primary-low-opacity;
	textDark: $textDark;
	textLight: $textLight;

	accent1: $accent1;
	accent1-low-opacity: $accent1-low-opacity;
	accent2: $accent2;
	accent2-low-opacity: $accent2-low-opacity;
	accent3: $accent3;
	coralRed: $coralRed;
	coralRedLight: $coralRedLight;
	aquaBlue: $aquaBlue;
	aquaBlueLight: $aquaBlueLight;

	// All neutral colors
	neutral1: $neutral1;
	neutral2: $neutral2;
	neutral3: $neutral3;
	neutral4: $neutral4;
	neutral5: $neutral5;
	neutral6: $neutral6;
	neutral6_5: $neutral6_5;
	neutral7: $neutral7;

	//Alert colors
	error: $error;
	warning: $warning;
	success: $success;
	//Alert background colors
	errorLight: $errorLight;
	warningLight: $warningLight;
	successLight: $successLight;
	//Benchmark accordion background colors
	errorMedium: $errorMedium;
	warningMedium: $warningMedium;
	successMedium: $successMedium;
	//Alert text colors
	errorDark: $errorDark;
	warningDark: $warningDark;
	successDark: $successDark;
	//Colors for cooling loads
	cooling: $cooling;
	coolingLight: $coolingLight;

	// Misc. colors
	accordionBg: $accordionBg;

	// font sizes
	inputLabels: $inputLabels;

	//EE colors
	moderate: $moderate;
	critical: $critical;

	moderateBackground: $moderateBackground;
	criticalBackground: $criticalBackground;

	// Icon size
	iconSize: $iconSize;

	// Row height
	rowHeight: $rowHeight;
}
