@use '../../styles/global' as g;

.popupButton {
	text-transform: none;
	width: 50%;
	font-family: 'Inter', sans-serif;
	color: g.$black;
	&:hover {
		border: 1px solid g.$primary !important;
	}
	&:global(.Mui-selected) {
		background-color: g.$primary;
		color: g.$white;
		&:hover {
			background-color: g.$primary;
		}
	}
}

.popupButtonGroupContainer {
	justify-content: center;
	width: 100%;
	height: 45px;
}
