@use '../../styles/global' as g;

.pill {
	border-radius: 15px;
	height: 30px;
	font-size: 0.8rem;
	opacity: 100% !important;
	display: flex;
	align-items: center;
	padding: 0 10px;
	font-weight: 500;

	&__neutral {
		background-color: g.$neutral3;
		color: g.$neutral7;
		padding: 0 10px;
		border-radius: 20px;
	}

	svg {
		color: white;
		border-radius: 100%;
		width: 20px;
		height: auto;
	}

	&__selected {
		background-color: #edf7f4;
		color: g.$primary;
		svg {
			background-color: g.$primary;
		}
	}
	&__unselected {
		background-color: #f5f5f5;
		color: g.$neutral7;
		svg {
			background-color: g.$neutral6_5;
		}
	}

	&__schedule {
		position: absolute;
		right: 0;
		top: 0;
		z-index: 1;
		margin-right: 50px;
		margin-top: 9px;
	}

	div {
		display: flex;
		gap: 6px;
		align-items: center;
	}
}

.dataSharingButtons {
	display: flex;
	justify-content: flex-end;
	margin-left: auto;
	margin-top: 20px;
	display: flex;
	gap: 18px;
}

.popupContainer {
	padding: 20px;
}

.closeButton__icon {
	min-width: 30px;
	min-height: 30px;
	color: g.$primary;
}

.submitButtonContainer {
	display: flex;
	justify-content: flex-end;
	margin: 5px 20px;
	border-top: 1px solid g.$neutral3;
}
