@use './global' as g;

.radioButton {
	color: g.$gray;
}

.checkbox {
	color: g.$gray;
	&:global(.Mui-checked) {
		color: g.$primary;
	}
}

// Styling for the text area. The height of 110 px allows for 4 lines (at 22px each) + 10px padding at top and bottom without scrolling,
// then scrolls after 4 lines. Export so it can be used for other text areas in the input sheet.
.textArea {
	width: 100%;
	font-size: g.$inputLabels;
	font-family: 'Inter', sans-serif;
	letter-spacing: 0;
	line-height: 22px;
	resize: none;
	height: 110px;
	padding-top: 10px;
	padding-bottom: 10px;
	margin-bottom: 10px;
}

.textArea::placeholder {
	color: g.$textPlaceholder;
	font-size: g.$inputLabels;
}

.default-radio-input {
	.radio-option {
		margin-bottom: 16px; // Default spacing between options

		&.has-tooltip {
			margin-bottom: 24px; // Extra spacing when tooltip is present
		}

		&.no-tooltip {
			margin-bottom: 16px; // Standard spacing for options without tooltip
		}

		.default-radio-tooltip {
			color: g.$textLight;
			font-size: g.$description;
			margin-top: 4px; // Spacing between the label and tooltip
			display: block; // Ensure tooltip starts on a new line
		}
	}
}
