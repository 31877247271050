@use './global' as g;

.fullPageTitle {
	font-family: 'Poppins', serif;
	font-size: g.$pageTitle;
	font-weight: bolder;
	color: g.$white;
}

.fullPageTitleSubtext {
	margin: 2% 0px 30px 0px;
	height: 10px;
	font-weight: 400;
	font-size: 1rem;
}

.keyTitle {
	font-family: 'Poppins', serif;
	color: g.$primary;
	font-size: 1.2rem;
	font-weight: 600;
}

.cardTitle {
	font-family: 'Poppins', serif;
	font-size: g.$cardTitle;
	font-weight: g.$boldWeight;
	text-transform: capitalize;
}

.cardSmallTitle {
	font-size: g.$description;
	font-weight: g.$boldWeight;
	margin-bottom: 10px;
}

.cardDescription {
	font-size: g.$description;
}

.cardInstructions {
	color: g.$textLight;
	font-size: g.$description;
	margin-top: 0;
}

.boldText {
	font-weight: 600;
}

.keyText {
	font-size: g.$keyText;
	line-height: g.$keyTextLineHeight;
}

.keyNumber {
	font-family: 'Poppins', serif;
	font-weight: bolder;
	font-size: 2.8rem;
}

.keyNumber2 {
	font-family: 'Poppins', serif;
	font-weight: bolder;
	font-size: 1.6rem;
}

.keyNumber3 {
	font-family: 'Poppins', serif;
	font-weight: bolder;
	font-size: 1.2rem;
}

.addButtonText {
	text-transform: uppercase;
	font-size: 0.85rem;
	color: g.$white;
}

.astronautLoaderText {
	font-weight: bolder;
	color: g.$primary;
}

// Percentage change chips
.changeInData {
	font-size: g.$keyText;
	border-radius: 18px;
	display: inline-block;
	margin-right: 9px;
	margin-top: 5px;
	padding: 6px 20px;
	text-align: center;
	font-weight: 600;
}

.goodText {
	color: g.$primary;
}

.badText {
	color: g.$errorDark;
}
.dark {
	background-color: g.$black2;
}

.good {
	@extend .goodText;
	background-color: #edf8f3;
}

.success {
	@extend .goodText;
	background-color: #edf8f3;
}
.bad {
	@extend .badText;
	background-color: g.$errorLight;
}

.neutral {
	background-color: g.$neutral2;
	color: #3c3c3c;
}

.greenText {
	font-weight: 600;
	color: #0b3d39;
}

.lightText {
	color: #909090;
}

.darkSpan {
	font-weight: bolder;
	display: inline-block;
	padding-bottom: 10px;
}
