.mfaEnrollmentContainer {
	width: 500px;

	.title {
		padding-bottom: 20px;
		width: 100%;
	}

	.codesToAdd {
		display: flex;
		flex-direction: column;
		align-items: center;

		.qrCode {
			width: 300px;
		}
	}

	.verificationCode {
		width: 100%;
		padding-top: 20px;
	}
}

.verificationInput {
	width: calc(100% - 14px);
	margin: 8px 0px;
	padding: 4px 6px;
	height: 32px;
}

.submitButton {
	margin-top: 10px;
}

.spacedOutTitles {
	display: flex;
	flex-direction: column;
	gap: 3px;
}
