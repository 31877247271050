@use '../../../styles/global' as g;

.numberfieldContainer {
	width: 100%;

	.unit {
		text-align: right;
		position: relative;
		width: 100px;
		display: inline-block;
		top: -32px;
		right: 16px;
		left: calc(100% - 120px);
	}
}

.numberField {
	border-radius: 6px;
	width: 25%;
}

.numberField::placeholder {
	font-size: g.$inputLabels;
}

/* remove arrow indicator in number field */
/* Chrome, Safari, Edge, Opera */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
.numberField {
	-moz-appearance: textfield;
}

.numberIndicators {
	display: flex;
	flex-direction: column;
	cursor: pointer;
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
}

.numberIndicators > span:first-child {
	border: 1px solid #e7e7e7;
	border-top-right-radius: 6px;
	border-left: 0px;
	padding: 0px 5px;
}

.numberIndicators > span:last-child {
	border-bottom: 1px solid #e7e7e7;
	border-right: 1px solid #e7e7e7;
	border-bottom-right-radius: 6px;
	padding: 0.5px 5px;
}

.numberIndicators span:hover {
	color: white;
	background-color: g.$primary;
}

.errorMessage {
	color: g.$error;
	margin-top: 8px;
}

.errorOutline,
.errorOutline:focus,
.errorOutline:hover {
	outline: g.$error solid 1px !important;
}
