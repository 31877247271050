@import '../../styles/Fonts.module.scss';

.messageContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: calc(100% - 100px);
	flex-grow: 1;
	min-height: 200px;

	p {
		text-align: center;
		max-width: 300px;
	}
}

.successMessage p {
	@extend .keyTitle;
}

.fullPageMessage {
	display: flex;
	height: calc(100vh - 140px);
	background-color: white;
	border-radius: 12px;

	.contentContainer {
		display: flex;
		background-color: #f6fdf9;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		margin: 40px;

		p {
			@extend .keyTitle;
			width: 360px;
			text-align: center;
			height: 100px;
		}
	}
}
