@use '../components/inputSheet/popups/_popup.scss' as p;
@use '../styles/global' as g;

@mixin submitStateIcon {
	font-size: 80px;
	color: g.$primary;
	margin-bottom: 10px;
}

@mixin submitStateContainer {
	display: grid;
	justify-items: center;
	margin-top: 30vh;
}

@mixin submitStateHeading {
	color: g.$gray3;
	font-family: Open Sans;
	font-size: 26px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 30px;
	margin: 0 0 20px 0;
}

@mixin submitStateText {
	color: g.$gray3;
	font-family: Open Sans;
	font-size: 24px;
	font-weight: normal;
	letter-spacing: 0;
	line-height: 26px;
	margin: 0;
	width: 60%;
	margin: auto;
	text-align: center;
	margin-top: 20px;
}

@mixin button {
	margin-left: auto;
	margin-right: auto;
	font-weight: 600;
	border-radius: 6px;
	text-transform: none;
}

.tabPanel__container {
	height: 100%;
}

.loading__container--submitted {
	@extend .loading__container;
	margin-top: 38vh;
}

.loading__container {
	@include submitStateContainer;
	margin-top: 40vh;
}

.loading__text {
	@include submitStateText;
	font-size: 24px;
}

.success__container {
	@include submitStateContainer;
}

.success__icon {
	@include submitStateIcon;
}

.success__heading {
	@include submitStateHeading;
}

.success__text {
	@include submitStateText;
}

.failure__container {
	@include submitStateContainer;
}

.failure__icon {
	@include submitStateIcon;
	color: g.$error;
}

.failure__heading {
	@include submitStateHeading;
}

.failure__text {
	@include submitStateText;
}

.closeButton__icon {
	min-width: 40px;
	min-height: 40px;
}

.inputSheet {
	&__container {
		display: flex;
		background-color: g.$neutral1;
		min-height: 100vh;
	}
	&__contentContainer {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		margin-left: 300px;
		padding: 0 40px;
	}
	&__buttonContainer {
		align-items: center;
		justify-content: flex-end;
		border-top: solid 1px g.$neutral3;
		padding-top: 20px;
		padding-bottom: 20px;
		position: fixed;
		width: calc(100% - 380px);
		bottom: 0;
		background: white;
		z-index: 5;
	}
}

.InputSheet_tabs__container > :last-child {
	margin-bottom: 40px;
}

.tabs {
	&__outerContainer {
		background-color: #fafafa;
		position: fixed;
		width: 300px;
	}
	&__container {
		padding: 20px 30px;
		height: 100vh;
		overflow-y: auto;

		> div:last-child {
			margin-bottom: 40px !important;
		}
	}
	&__title {
		&__description {
			color: g.$neutral6_5;
		}
	}
}

.section__content {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow-y: clip;
	background-color: g.$neutral1;

	.header {
		font-size: 18px;
		font-weight: 600;
		color: g.$neutral7;

		&__container {
			border-bottom: 1px solid g.$neutral3;
			display: flex;
			justify-content: space-between;
			position: fixed;
			width: calc(100% - 380px);
			padding-top: 20px;
			background: white;
			z-index: 5;

			h1 {
				margin: 5px 0 10px;
			}
		}
	}

	.content {
		margin-top: 100px;
		margin-bottom: 100px;

		&__instruction {
			width: 600px;
			background-color: #fafafa;
			border-radius: 6px;
			padding: 10px 20px;
			display: flex;
			align-items: center;
			gap: 10px;
		}
	}

	.subheader {
		font-size: 11px;
		color: g.$primary;
		display: block;
		text-transform: uppercase;
	}

	.tabPanels__container {
		overflow-y: auto;
		flex-grow: 1;
		padding-top: 24px;
	}
}

.tooltip {
	margin-top: 6px;

	&__title {
		font-size: 18px;
	}
}

.button {
	@include button;
}

.savePopupContainer {
	.savePopupTitleContainer {
		width: 360px;

		.title {
			font-family: 'Poppins', sans-serif;
			font-size: 16px;
			font-weight: 700;
			color: g.$primary;
		}

		.text {
			flex-direction: column;
		}

		display: flex;
		align-items: center;
		gap: 40px;
		padding: 0;
		padding-bottom: 20px;

		img {
			min-width: 120px;
		}
	}

	.savePopupActions {
		display: flex;
		justify-content: flex-end;
		margin: 0;
		padding: 0;
		border-top: 1px solid #dddddd;

		button {
			margin-top: 20px;
		}

		.secondaryButton {
			color: #909090;
		}
	}
}
