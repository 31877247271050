@use '../../../styles/global' as g;
@use '../../../styles/inputs';

$pFCMarginBottom: 35px;

@mixin sectionContainer {
	max-width: 700px;
}

// field contains both the label and input
@mixin popupFieldContainer {
	display: flex;
	gap: 40px;
	padding: 24px 0;
}

@mixin popupInputLabel {
	font-family: 'Inter';
	font-size: g.$inputLabels;
	font-weight: 600;
	line-height: 15px;
	color: g.$neutral7;
	margin: 0 0 3px 0;
	display: block;
}

@mixin popupInputContainer {
	flex: 1;
}

@mixin subfieldContainer {
	display: flex;
	gap: 40px;
	background-color: g.$gray2;
	margin: 20px 0 30px;
}

// checkboxes, etc.
@mixin popupVCenteredContainer {
	margin-top: auto;
	margin-bottom: auto;
}

.section__container {
	@include sectionContainer;
	margin-bottom: $pFCMarginBottom;
	max-width: 1100px;
}

.popups_section__container {
	max-width: 1100px;
}

// default classes available
.popupContainer {
	padding: 20px;
}

.popupContentContainer {
	padding-bottom: 0px;
}

.popupFieldContainer {
	@include popupFieldContainer;
}

.info {
	font-family: 'Inter';
	&__container {
		flex: 1;
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		align-items: flex-start;
	}
	&__helper-text {
		font-style: normal;
		font-weight: 400;
		font-size: 0.85rem;
		line-height: 18px;
		color: g.$neutral5;
		display: block;
		margin-top: 5px;
		text-align: left;
		white-space: pre-line;
	}
	&__helper-subfield {
		padding-left: 20px;
	}
}

.popupInputLabel {
	@include popupInputLabel;
}

.popupInputLabel--subfield {
	@include popupInputLabel;
	padding-left: 20px;
}

.popupInputContainer {
	@include popupInputContainer;
}

.popupSubfieldContainer {
	@include subfieldContainer;
}

.popupVCenteredContainer {
	@include popupVCenteredContainer;
}

input::placeholder {
	color: g.$neutral6_5;
	font-size: g.$inputLabels;
}
