@use '../../../styles/global' as g;

.DataSharingDialog {
	.subtitle {
		font-style: normal;
		font-weight: 400;
		font-size: 0.85rem;
		color: #818181;
		margin: 0;
	}

	.DialogTitle {
		font-weight: 600;
		font-size: 1rem;
		padding: 22px 0;
		margin: 0 22px 12px;
		border-bottom: solid 1px #dddddd;
		display: flex;
		justify-content: space-between;
	}

	.newSectionTitle {
		margin-top: 26px;
	}

	textarea {
		width: 100%;
		box-sizing: border-box;
		padding: 6px 12px;
		font-family: 'Inter', sans-serif;
	}

	textarea:focus-visible {
		outline: g.$primary auto 1px;
	}

	.MuiCheckbox-root {
		padding: 5px 5px 5px 10px;
	}

	.closeButton__icon {
		min-width: 30px;
		min-height: 30px;
	}

	.toggle-button-container {
		display: flex;
		gap: 0;
	}

	.toggle-button-container button {
		background-color: white;
		border: 1px solid #dddddd;
		border-radius: 3px;
		color: #212121;
		width: 100%;
		height: 35px;
		box-shadow: none;

		&.yes {
			background-color: #004225; // Set color for "Yes"
			color: white;
		}

		&.no {
			background-color: #f44336; // Set color for "No"
			color: white;
		}

		&.disabled {
			background-color: rgba(0, 0, 0, 0.12);
		}
	}

	.enery-direct-access {
		display: flex;
		flex-direction: column;
		gap: 10px;

		.description {
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 15px;
			color: #a6a6a6;
		}

		.account-details {
			display: flex;
			flex-direction: column;
			width: 100%;
			gap: 14px;
			margin-bottom: 10px;

			.label-input-container {
				display: flex;
				align-items: center;
				gap: 10px;

				.label {
					width: 43%;
					margin: 0;
					line-height: 46px;
				}

				.input-container {
					width: 57%;
				}
			}
		}
	}

	.AccountConfirmation {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px;
		margin-bottom: 10px;

		.upper-div {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 10px;
		}

		.message {
			font-style: normal;
			font-weight: 400;
			line-height: 15px;
			color: #212121;
		}
	}

	.ConnectionSteps {
		.step {
			display: flex;
			align-items: center;
			gap: 5px;
			margin-bottom: 15px;

			.bold {
				font-weight: bold;
			}
		}

		.step-description {
			flex: 1;
		}

		.checking-connection-spinner {
			padding-left: 5px;
		}
	}

	// The circle behind the step number
	.step-number {
		font-weight: bold;
		margin-right: 5px;
		display: block;
		height: 36px;
		width: 36px;
		max-width: 36px;
		line-height: 36px;

		-moz-border-radius: 18px; /* or 50% */
		border-radius: 18px; /* or 50% */

		background-color: #004225;
		color: white;
		text-align: center;
		font-size: 14px;
	}

	.FileUploadContainer {
		.valid-file {
			color: #004225;
		}

		.invalid-file {
			color: red;
		}

		.SingleUpload {
			display: flex;
			justify-content: space-between;
			width: 100%;
		}

		.MultipleUpload {
			border-bottom: 1px solid #dddddd;
			border-radius: 0px;

			.MuiAccordion-root {
				box-shadow: none;
				min-height: 3.9rem;
			}

			.MuiAccordionSummary-root {
				padding: 10px 0px 5px;

				.MuiAccordionSummary-content {
					margin: 6px 0;
				}
			}

			.MuiAccordionDetails-root {
				padding: 0;
			}

			.accordion-summary {
				flex-direction: row-reverse;

				.summary {
					display: flex;
					justify-content: space-between;
					width: 100%;
					min-width: 500px;

					.container {
						display: flex;
						flex-direction: column;
						gap: 4px;

						.header {
							display: flex;
							flex-direction: row;
							align-items: center;
							gap: 10px;

							.document-name {
								font-size: 0.85rem;
								line-height: 0.9rem;
								color: #212121;
							}
						}

						.valid-filetypes {
							font-size: 0.85rem;
							color: #a6a6a6;

							.types {
								text-transform: uppercase;
							}
						}
					}

					.file-upload-container {
						display: flex;
						flex-direction: column;
						align-items: flex-end;

						.file-count {
							font-size: 0.85rem;
							text-decoration: underline;
							color: #004225;
						}

						.uploadButton {
							display: flex;
							align-items: center;
							gap: 5px;
						}

						.deleteInfo {
							font-size: 0.9rem;
							color: #ff4f42;
						}
					}
				}
			}

			.accordion-detail {
				ul li:not(:first-child) {
					border-top: 1px solid #dddddd;
				}

				.files {
					display: flex;
					width: 100%;
					gap: 5px;

					.actions-container {
						display: flex;
						gap: 1.3rem;
					}
				}
			}
		}

		.MultipleUpload:first-of-type {
			border-top: 0px;
		}
	}
}

.DataSharingButtons {
	display: flex;
	justify-content: flex-end;
	margin-left: auto;
	margin-top: 20px;
	display: flex;
	gap: 18px;
	width: 100%;

	.backButton {
		text-decoration: underline;
	}
}

.InputContainer {
	display: flex;
	flex-direction: row;
	gap: 30px;
	padding-top: 30px;

	label {
		display: flex;
		flex-direction: column;
	}

	.labelTitle {
		font-weight: 600;
		padding-bottom: 10px;
	}
}

.InstructionsContainer {
	display: flex;
	flex-direction: column;
	padding-top: 20px;

	.TopInstruction {
		padding-bottom: 5px;
	}
}

.accordionContents {
	display: flex;
	flex-direction: column;
	padding: 0px 0px 0px 20px;
}

.takeoffLocationSelectionPopup {
	.takeoffLocation {
		width: 50%;
		margin-right: 20px;
	}
	.takeoffLocationImage {
		width: 50%;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			object-fit: contain;
			width: 100%;
		}
	}
}

.MuiPickersLayout-root {
	font-family: Inter;
	background-color: #fafafa;
	border-radius: 10px;
}

.MuiPickersCalendarHeader-label {
	font-family: 'Inter', sans-serif;
	font-size: 13px;
}

.guidedSessionInstructionsTitle {
	display: flex;

	.step-number {
		flex: 1;
		margin-top: 2px;
	}

	p {
		flex: 1;
		.title {
			margin-bottom: 10px;
			display: inline-block;
		}
	}

	.subTitles {
		margin: 0;
	}

	ul {
		margin: 0;
	}
}
